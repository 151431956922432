import { AppProps } from 'next/app';
import Head from 'next/head';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import '../styles/buttons.scss';
import '../styles/global.scss';

export default function App({ Component, pageProps }: AppProps) {
    return (
        <>
            <Head>
                <link rel="shortcut icon" type="image/webp" href="/images/favicon.webp"></link>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title key="noria-title">Noria.no - IT for financial services</title>
                <meta
                    property="og:title"
                    content="Noria.no - IT for financial services"
                    key="noria-og-title"
                />
                <meta
                    name="description"
                    content="The official website for Noria Group, including it's 3 companies; Noria Software, Noria Digital and Noria Consulting."
                    key="noria-desc"
                />
                <meta
                    property="og:description"
                    content="The official website for Noria Group, including it's 3 companies; Noria Software, Noria Digital and Noria Consulting."
                    key="noria-og-desc"
                />
                <meta
                    name="google-site-verification"
                    content="Q0vC4dPlVR0NvF_rRsQXzQteiC4wSPtoBDQ459PZdao"
                />
            </Head>
            <CookiesProvider>
                <Component {...pageProps} />
            </CookiesProvider>
        </>
    );
}
